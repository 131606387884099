// API
import CreditMemoApi from '@/api/CreditMemoApi';

// Utils
import ObjectUtil from '@/utils/ObjectUtil';
import StoreUtil from '@/utils/StoreUtil';
import FormUtil from '@/utils/FormUtil';
import FormatUtil from '@/utils/FormatUtil';

export const fullNameSpace = 'CreditMemo/Detail/';

export const mutationTypes = {
    SET_IN_FLIGHT: 'SET_IN_FLIGHT',
    SET_SUPPLIER: 'SET_SUPPLIER',
    SET_TOTAL: 'SET_TOTAL',
    SET_DATE: 'SET_DATE',
    SET_CALCULATED_TOTAL: 'SET_CALCULATED_TOTAL',
    SET_CALCULATED_LINE_TOTAL: 'SET_CALCULATED_LINE_TOTAL',
    SET_CREDIT_MEMO_ID: 'SET_CREDIT_MEMO_ID',
    SET_LOCATION: 'SET_LOCATION',
    SET_CREDIT_MEMO_TOTAL_UNIT_COUNT: 'SET_CREDIT_MEMO_TOTAL_UNIT_COUNT',
    SET_DOCUMENT_STATUS: 'SET_DOCUMENT_STATUS',
    SET_NOTES: 'SET_NOTES',
    SET_HISTORICAL_NOTES: 'SET_HISTORICAL_NOTES',
    SET_CREDIT_MEMO_NUMBER: 'SET_CREDIT_MEMO_NUMBER',
    SET_ADJUSTMENTS: 'SET_ADJUSTMENTS',
    SET_ADJUSTMENT_FEE_AMOUNT: 'SET_ADJUSTMENT_FEE_AMOUNT',
    SET_ADJUSTMENT_CREDIT_AMOUNT: 'SET_ADJUSTMENT_CREDIT_AMOUNT',
    SET_CREDIT_MEMO_ITEMS: 'SET_CREDIT_MEMO_ITEMS',
    REMOVE_CREDIT_MEMO_ITEM: 'REMOVE_CREDIT_MEMO_ITEM',
    SET_CREDIT_MEMO_ITEM_QUANTITY: 'SET_CREDIT_MEMO_ITEM_QUANTITY',
    SET_CREDIT_MEMO_ITEM_PRICE: 'SET_CREDIT_MEMO_ITEM_PRICE',
    SET_REQUEST_FOR_RETURN_AUTHORIZATIONS: 'SET_REQUEST_FOR_RETURN_AUTHORIZATIONS',
    SET_SUPPLIER_RETURN_TRANSACTIONS: 'SET_SUPPLIER_RETURN_TRANSACTIONS',
    GET_RETURN_TRANSACTIONS_IN_FLIGHT: 'GET_RETURN_TRANSACTIONS_IN_FLIGHT',
};

export const actionTypes = {
    update: 'update',
    getCreditMemoById: 'getCreditMemoById',
    reset: 'reset',
    delete: 'delete',

    getReturnTransactionsBySupplier: 'getReturnTransactionsBySupplier',
    getReturnTransactionsInFlight: 'getReturnTransactionsInFlight',
    setSupplierReturnTransactions: 'setSupplierReturnTransactions',
    setCreditMemoInfoFromApi: 'setCreditMemoInfoFromApi',
    setCreditMemoDetailInformation: 'setCreditMemoDetailInformation',
    setSupplier: 'setSupplier',
    setTotal: 'setTotal',
    setDate: 'setDate',
    setCalculatedTotal: 'setCalculatedTotal',
    setCalculatedLineTotal: 'setCalculatedLineTotal',
    setCreditMemoId: 'setCreditMemoId',
    setLocation: 'setLocation',
    setCreditMemoTotalUnitCount: 'setCreditMemoTotalUnitCount',
    setDocumentStatus: 'setDocumentStatus',
    setNotes: 'setNotes',
    setHistoricalNotes: 'setHistoricalNotes',
    setCreditMemoNumber: 'setCreditMemoNumber',
    setAdjustments: 'setAdjustments',
    setAdjustmentFeeAmount: 'setAdjustmentFeeAmount',
    setAdjustmentCreditAmount: 'setAdjustmentCreditAmount',
    setCreditMemoItems: 'setCreditMemoItems',
    setReturnTransactions: 'setReturnTransactions',
};

export const getDefaultAdjustments = () => ( {
    FEES: {
        amount: 0,
        type: 'FEES',
    },
    CREDIT: {
        amount: 0,
        type: 'CREDIT',
    },
} );

export default {
    namespaced: true,
    state: {
        /** @type { Boolean } */
        inFlight: false,
        /** @type { Boolean } */
        getReturnTransactionsInFlight: false,

        // Credit Memo Detail Information
        info: {
            supplier: {},
            total: 0,
            date: '',
            calculatedTotal: 0,
            calculatedLineTotal: 0,
            creditMemoId: 0,
            location: {},
            creditMemoTotalUnitCount: 0,
            documentStatus: '',
            notes: '',
            historicalNotes: '',
            creditMemoNumber: '',
            adjustments: getDefaultAdjustments(),
            creditMemoItems: [],
            /** @type { import('@/typedefs/transaction').transaction[] } */
            returnTransactions: [],

            // Modal data
            /** @type { import('@/typedefs/transaction').transaction[] } */
            supplierReturnTransactions: [],
        },
    },
    getters: {
        displayedCreditMemoItems: ( state ) => state.info.creditMemoItems.filter( ( item ) => item.active ),
        /** @returns { String } */
        creditMemoLinesTotal: ( state, getters ) => FormatUtil.toCurrency(
            getters.displayedCreditMemoItems.reduce(
                ( runningTotal, item ) => runningTotal + ( +item.quantity * +item.price ),
                0,
            ),
        ),
    },
    actions: {
        async [ actionTypes.reset ]( context ) {
            context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: false } );
            await context.dispatch( actionTypes.setCreditMemoDetailInformation, {
                supplier: {},
                date: '',
                total: 0,
                calculatedTotal: 0,
                calculatedLineTotal: 0,
                creditMemoId: 0,
                location: {},
                creditMemoTotalUnitCount: 0,
                documentStatus: '',
                notes: '',
                historicalNotes: '',
                creditMemoNumber: '',
                adjustments: [],
                creditMemoItems: [],
                returnTransactions: [],
            } );
        },

        /**
         * Search for Return Transactions by filters
         */
        async [ actionTypes.getReturnTransactionsBySupplier ]( context, { startDate, endDate } ) {
            if ( context.state.info.supplier === null
                || !( 'supplierId' in context.state.info.supplier )
            ) {
                throw new Error( 'Failed to search for return transactions: Credit Memo has no valid supplier.' );
            }
            await context.commit( mutationTypes.GET_RETURN_TRANSACTIONS_IN_FLIGHT, { boolean: true } );
            await context.dispatch( actionTypes.setSupplierReturnTransactions, { list: [] } );
            try {
                const returnTransactionLines = await context.dispatch(
                    'CreditMemo/getRRAs',
                    {
                        supplierId: context.state.info.supplier.supplierId,
                        ...FormUtil.insertIf( !!startDate, {
                            searchTransCreatedStartDate: startDate,
                        } ),
                        ...FormUtil.insertIf( !!endDate, {
                            searchTransCreatedEndDate: endDate,
                        } ),
                    },
                    { root: true },
                );
                context.dispatch( actionTypes.setSupplierReturnTransactions, { list: returnTransactionLines } );
            } catch ( error ) {
                const message = `Failed to find any Return Transactions matching the filter criteria - ${ StoreUtil.error.getMessage( error ) }`;
                context.dispatch( 'setErrorNotification', message, { root: true } );
                context.dispatch( actionTypes.setSupplierReturnTransactions, {
                    list: [],
                } );
            } finally {
                await context.commit( mutationTypes.GET_RETURN_TRANSACTIONS_IN_FLIGHT, { boolean: false } );
            }
        },

        [ actionTypes.setSupplierReturnTransactions ]( context, { list } ) {
            context.commit( mutationTypes.SET_SUPPLIER_RETURN_TRANSACTIONS, { list } );
        },

        [ actionTypes.getReturnTransactionsInFlight ]( context, { boolean } ) {
            context.commit( mutationTypes.GET_RETURN_TRANSACTIONS_IN_FLIGHT, { boolean } );
        },

        async [ actionTypes.getCreditMemoById ]( context, { creditMemoId } ) {
            context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: true } );
            try {
                const { data } = await CreditMemoApi.getCreditMemoById( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    creditMemoId,
                } );
                /** @type { import('@/typedefs/purchaseOrder').purchaseOrder[] } */
                context.dispatch( actionTypes.setCreditMemoInfoFromApi, data );
            } catch ( error ) {
                throw new Error( error.message );
            } finally {
                context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: false } );
            }
        },
        async [ actionTypes.delete ]( context ) {
            context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: true } );
            try {
                await CreditMemoApi.deleteCreditMemo( {
                    authToken: context.rootGetters[ 'User/authString' ],
                    creditMemoId: context.state.info.creditMemoId,
                } );
            } catch ( error ) {
                const message = `Error deleting the credit memo ${ context.state.info.creditMemoId } - ${ StoreUtil.error.getMessage( error ) }`;
                context.dispatch( 'setErrorNotification', message, { root: true } );
                throw new Error( error );
            } finally {
                context.commit( mutationTypes.SET_IN_FLIGHT, { boolean: true } );
            }
        },
        async update(
            {
                commit, state, rootGetters, dispatch,
            },
            { returnTransactionLines = [], updateFromResults = false } = {},
        ) {
            commit(mutationTypes.SET_IN_FLIGHT, { boolean: true });
            try {
                const adjustmentInboundDtos = Object.keys(state.info.adjustments).map((key) => ({
                    type: state.info.adjustments[key].type,
                    amount: Math.abs( state.info.adjustments[key].amount),
                    positive: Math.sign(state.info.adjustments[key].amount) >= 0,
                }));

                const { data } = await CreditMemoApi.updateCreditMemo({
                    authToken: rootGetters['User/authString'],
                    creditMemo: {
                        creditMemoId: state.info.creditMemoId,
                        date: state.info.date,
                        notes: state.info.notes,
                        total: state.info.total,
                        documentStatus: state.info.documentStatus,
                        adjustmentInboundDtos,
                        ...FormUtil.insertIf(
                            !!returnTransactionLines && returnTransactionLines.length > 0,
                            {
                                rraItemIdsToAdd: returnTransactionLines.map((line) => line.rraItemId),
                            },
                        ),
                        // We only send specific fields on to the backend for the unreconciled lines
                        creditMemoItemUpdateInboundDtos: state.info.creditMemoItems.map(({
                            creditMemoItemId, quantity, price, active,
                        }) => ({
                            creditMemoItemId,
                            qty: quantity,
                            price,
                            active,
                        })),
                    },
                });
                if (updateFromResults) {
                    dispatch(actionTypes.setCreditMemoInfoFromApi, data);
                }
                return data;
            } catch (error) {
                throw new Error(error);
            } finally {
                commit(mutationTypes.SET_IN_FLIGHT, { boolean: false });
            }
        },
        [ actionTypes.setCreditMemoInfoFromApi ]( context, data ) {
            // API returns a list of results - but there should never be more than 1 result
            const creditMemoInfo = data.creditMemoDtos[ 0 ];
            /** @type { import('@/typedefs/purchaseOrder').purchaseOrder[] } */
            context.dispatch( actionTypes.setCreditMemoDetailInformation, {
                supplier: creditMemoInfo.supplier,
                date: creditMemoInfo.date,
                total: creditMemoInfo.total,
                calculatedTotal: creditMemoInfo.calcTotal,
                calculatedLineTotal: creditMemoInfo.calcLineTotal,
                creditMemoId: creditMemoInfo.creditMemoId,
                location: creditMemoInfo.location,
                creditMemoTotalUnitCount: creditMemoInfo.cmTotalUnitCount,
                documentStatus: creditMemoInfo.documentStatus,
                notes: creditMemoInfo.notes,
                historicalNotes: creditMemoInfo.historical,
                creditMemoNumber: creditMemoInfo.creditMemoNumber,
                adjustments: creditMemoInfo.adjustments,
                creditMemoItems: creditMemoInfo.creditMemoItemDtos,
                returnTransactions: creditMemoInfo.requestTransAdvSearchResultDtos,
            } );
        },
        [ actionTypes.setCreditMemoDetailInformation ]( context, {
            supplier,
            total,
            date,
            calculatedTotal,
            calculatedLineTotal,
            creditMemoId,
            location,
            creditMemoTotalUnitCount,
            documentStatus,
            notes,
            historicalNotes,
            creditMemoNumber,
            adjustments,
            creditMemoItems,
            returnTransactions,
        } ) {
            context.dispatch( actionTypes.setSupplier, { supplier } );
            context.dispatch( actionTypes.setTotal, { total } );
            context.dispatch( actionTypes.setDate, { date } );
            context.dispatch( actionTypes.setCalculatedTotal, { calculatedTotal } );
            context.dispatch( actionTypes.setCalculatedLineTotal, { calculatedLineTotal } );
            context.dispatch( actionTypes.setCreditMemoId, { creditMemoId } );
            context.dispatch( actionTypes.setLocation, { location } );
            context.dispatch( actionTypes.setCreditMemoTotalUnitCount, { creditMemoTotalUnitCount } );
            context.dispatch( actionTypes.setDocumentStatus, { documentStatus } );
            context.dispatch( actionTypes.setNotes, { notes } );
            context.dispatch( actionTypes.setHistoricalNotes, { historicalNotes } );
            context.dispatch( actionTypes.setCreditMemoNumber, { creditMemoNumber } );
            context.dispatch( actionTypes.setAdjustments, { adjustments } );
            context.dispatch( actionTypes.setCreditMemoItems, { creditMemoItems } );
            context.dispatch( actionTypes.setReturnTransactions, { returnTransactions } );
        },
        [ actionTypes.setAdjustments ]( context, { adjustments } ) {
            const defaultAdjustments = getDefaultAdjustments();
            const newAdjustments = ObjectUtil.normalize( adjustments, 'type' );

            // Ensures that FEES and CREDIT are initialized
            Object.keys( defaultAdjustments ).forEach( ( key ) => {
                if ( !newAdjustments[ key ] ) {
                    newAdjustments[ key ] = defaultAdjustments[ key ];
                } else if ( !newAdjustments[ key ].amount ) {
                    newAdjustments[ key ].amount = 0;
                }
            } );

            context.commit( mutationTypes.SET_ADJUSTMENTS, { adjustments: newAdjustments } );
        },
        [ actionTypes.setTotal ]( context, { total } ) {
            let newTotal = total;
            if ( !total ) {
                newTotal = 0;
            }
            context.commit( mutationTypes.SET_TOTAL, { total: newTotal } );
        },
        [ actionTypes.setCalculatedTotal ]( context, { calculatedTotal } ) {
            let newCalculatedTotal = calculatedTotal;
            if ( !calculatedTotal ) {
                newCalculatedTotal = 0;
            }
            context.commit( mutationTypes.SET_CALCULATED_TOTAL, { calculatedTotal: newCalculatedTotal } );
        },
        [ actionTypes.setCalculatedLineTotal ]( context, { calculatedLineTotal } ) {
            let newCalculatedLineTotal = calculatedLineTotal;
            if ( !calculatedLineTotal ) {
                newCalculatedLineTotal = 0;
            }
            context.commit( mutationTypes.SET_CALCULATED_LINE_TOTAL, { calculatedLineTotal: newCalculatedLineTotal } );
        },
        [ actionTypes.setAdjustmentFeeAmount ]( context, { amount } ) {
            context.commit( mutationTypes.SET_ADJUSTMENT_FEE_AMOUNT, { amount: Number.parseFloat( amount ) } );
        },
        [ actionTypes.setAdjustmentCreditAmount ]( context, { amount } ) {
            context.commit( mutationTypes.SET_ADJUSTMENT_CREDIT_AMOUNT, { amount: Number.parseFloat( amount ) } );
        },
        [ actionTypes.setCreditMemoItems ]( context, { creditMemoItems } ) {
            let newCreditMemoItems = creditMemoItems;
            if ( !creditMemoItems ) {
                newCreditMemoItems = [];
            }
            context.commit( mutationTypes.SET_CREDIT_MEMO_ITEMS, { creditMemoItems: newCreditMemoItems } );
        },
        [ actionTypes.setReturnTransactions ]( context, { returnTransactions } ) {
            let newRequestForReturnAuthorizations = returnTransactions;
            if ( !returnTransactions ) {
                newRequestForReturnAuthorizations = [];
            }
            context.commit( mutationTypes.SET_REQUEST_FOR_RETURN_AUTHORIZATIONS, { returnTransactions: newRequestForReturnAuthorizations } );
        },
        setDate({ commit }, { date }) {
            commit('SET_DATE', { date });
        },
        [ actionTypes.setSupplier ]( context, { supplier } ) {
            context.commit( mutationTypes.SET_SUPPLIER, { supplier } );
        },
        [ actionTypes.setCreditMemoId ]( context, { creditMemoId } ) {
            context.commit( mutationTypes.SET_CREDIT_MEMO_ID, { creditMemoId } );
        },
        [ actionTypes.setLocation ]( context, { location } ) {
            context.commit( mutationTypes.SET_LOCATION, { location } );
        },
        [ actionTypes.setCreditMemoTotalUnitCount ]( context, { creditMemoTotalUnitCount } ) {
            context.commit( mutationTypes.SET_CREDIT_MEMO_TOTAL_UNIT_COUNT, { creditMemoTotalUnitCount } );
        },
        [ actionTypes.setDocumentStatus ]( context, { documentStatus } ) {
            context.commit( mutationTypes.SET_DOCUMENT_STATUS, { documentStatus } );
        },
        [ actionTypes.setNotes ]( context, { notes } ) {
            context.commit( mutationTypes.SET_NOTES, { notes } );
        },
        [ actionTypes.setHistoricalNotes ]( context, { historicalNotes } ) {
            context.commit( mutationTypes.SET_HISTORICAL_NOTES, { historicalNotes } );
        },
        [ actionTypes.setCreditMemoNumber ]( context, { creditMemoNumber } ) {
            context.commit( mutationTypes.SET_CREDIT_MEMO_NUMBER, { creditMemoNumber } );
        },
    },
    mutations: {
        [ mutationTypes.SET_IN_FLIGHT ]( state, { boolean } ) {
            state.inFlight = boolean;
        },
        [ mutationTypes.SET_SUPPLIER ]( state, { supplier } ) {
            state.info.supplier = supplier;
        },
        [ mutationTypes.SET_TOTAL ]( state, { total } ) {
            state.info.total = total;
        },
        SET_DATE(state, { date }) {
            state.info.date = date;
        },
        [ mutationTypes.SET_CALCULATED_TOTAL ]( state, { calculatedTotal } ) {
            state.info.calculatedTotal = calculatedTotal;
        },
        [ mutationTypes.SET_CALCULATED_LINE_TOTAL ]( state, { calculatedLineTotal } ) {
            state.info.calculatedLineTotal = calculatedLineTotal;
        },
        [ mutationTypes.SET_CREDIT_MEMO_ID ]( state, { creditMemoId } ) {
            state.info.creditMemoId = creditMemoId;
        },
        [ mutationTypes.SET_LOCATION ]( state, { location } ) {
            state.info.location = location;
        },
        [ mutationTypes.SET_CREDIT_MEMO_TOTAL_UNIT_COUNT ]( state, { creditMemoTotalUnitCount } ) {
            state.info.creditMemoTotalUnitCount = creditMemoTotalUnitCount;
        },
        [ mutationTypes.SET_DOCUMENT_STATUS ]( state, { documentStatus } ) {
            state.info.documentStatus = documentStatus;
        },
        [ mutationTypes.SET_NOTES ]( state, { notes } ) {
            state.info.notes = notes;
        },
        [ mutationTypes.SET_HISTORICAL_NOTES ]( state, { historicalNotes } ) {
            state.info.historicalNotes = historicalNotes;
        },
        [ mutationTypes.SET_CREDIT_MEMO_NUMBER ]( state, { creditMemoNumber } ) {
            state.info.creditMemoNumber = creditMemoNumber;
        },
        [ mutationTypes.SET_ADJUSTMENTS ]( state, { adjustments } ) {
            state.info.adjustments = adjustments;
        },
        [ mutationTypes.SET_ADJUSTMENT_FEE_AMOUNT ]( state, { amount } ) {
            state.info.adjustments.FEES.amount = amount;
        },
        [ mutationTypes.SET_ADJUSTMENT_CREDIT_AMOUNT ]( state, { amount } ) {
            state.info.adjustments.CREDIT.amount = amount;
        },
        [ mutationTypes.SET_CREDIT_MEMO_ITEMS ]( state, { creditMemoItems } ) {
            state.info.creditMemoItems = creditMemoItems;
        },
        [ mutationTypes.SET_CREDIT_MEMO_ITEM_QUANTITY ]( state, { creditMemoItemId, quantity } ) {
            const item = state.info.creditMemoItems.find( ( creditMemoItem ) => creditMemoItem.creditMemoItemId === creditMemoItemId );
            item.quantity = quantity;
        },
        [ mutationTypes.SET_CREDIT_MEMO_ITEM_PRICE ]( state, { creditMemoItemId, price } ) {
            const item = state.info.creditMemoItems.find( ( creditMemoItem ) => creditMemoItem.creditMemoItemId === creditMemoItemId );
            item.price = price;
        },
        [ mutationTypes.SET_CREDIT_MEMO_ITEMS ]( state, { creditMemoItems } ) {
            state.info.creditMemoItems = creditMemoItems;
        },
        [ mutationTypes.REMOVE_CREDIT_MEMO_ITEM ]( state, { creditMemoItemId } ) {
            const item = state.info.creditMemoItems.find( ( creditMemoItem ) => creditMemoItem.creditMemoItemId === creditMemoItemId );
            item.active = false;
        },
        [ mutationTypes.SET_REQUEST_FOR_RETURN_AUTHORIZATIONS ]( state, { returnTransactions } ) {
            state.info.returnTransactions = returnTransactions;
        },
        [ mutationTypes.GET_RETURN_TRANSACTIONS_IN_FLIGHT ]( state, { boolean } ) {
            state.getReturnTransactionsInFlight = boolean;
        },
        [ mutationTypes.SET_SUPPLIER_RETURN_TRANSACTIONS ]( state, { list } ) {
            state.info.supplierReturnTransactions = list;
        },
    },
};
