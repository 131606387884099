// Api
import TitleApi from '@/api/TitleApi';
import LibraryApi from '@/api/LibraryApi';

export default {
    namespaced: true,
    state: {
        /** @type {Array<{ name: string, friendly: string, digitalVendorId: number, digitalVendorName: string, active: boolean, manuallyManaged: boolean }>} */
        selectedLibraries: [],
    },
    actions: {
        async fetchSelectedLibraries({ rootGetters, commit }, isbn) {
            try {
                const libraries = (
                    await TitleApi.getLibrariesForTitle(rootGetters['User/authString'], isbn)
                ).data;
                commit('SET_SELECTED_LIBRARIES', libraries);
            } catch (error) {
                console.error('Error fetching subscription libraries:', error);
            }
        },

        async addTitleToLibrary({ rootGetters, dispatch }, { libraryName, isbn }) {
            try {
                await LibraryApi.addTitle(rootGetters['User/authString'], libraryName, isbn);
                await dispatch('fetchSelectedLibraries', isbn);
            } catch (error) {
                if (error.response.data.message.includes('not manually managed')) {
                    dispatch('setErrorNotification', `You cannot add this title to ${libraryName} library`, { root: true });
                } else {
                    dispatch('setErrorNotification', error.response.data.message, { root: true });
                }
                console.error('Error adding title to library:', error);
            }
        },

        async removeTitleFromLibrary({ rootGetters, dispatch }, { libraryName, isbn }) {
            try {
                await LibraryApi.removeTitle(rootGetters['User/authString'], libraryName, isbn);
                await dispatch('fetchSelectedLibraries', isbn);
            } catch (error) {
                console.error('Error removing title from library:', error);
            }
        },
    },
    mutations: {
        SET_SELECTED_LIBRARIES(state, libraries) {
            state.selectedLibraries = libraries;
        },
    },
};
